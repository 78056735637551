import React from 'react';
import './header.css';

export const SalesHeader = ({ brand }) => (
  <header id="header" className="header sales" >
    <div className="mobile header-body">
      <h1 className="title"> spring &amp; summer </h1>
    </div>
    <div className="desktop header-body">
      <div className="title-box">
        <ul className="title-box_text">
          <li className="box-upper text">spring &amp; summer</li>
          <li className="box-bottom text">sales item catalogue</li>
        </ul>
      </div>
    </div>
  </header>
)

export const ChristmasHeader = ({ brand }) => (
  <header id="header" className="header sales christmas" >
    <div className="mobile header-body">
      <h1 className="title"> christmas decoration </h1>
    </div>
    <div className="desktop header-body">
      <div className="title-box">
        <ul className="title-box_text">
          <li className="box-upper text">autumn &amp; winter</li>
          <li className="box-bottom text">christmas decoration</li>
        </ul>
      </div>
    </div>
  </header>
)

export const StockBookHeader = () => (
  <header id="header" className="header sales stockbook" >
    <div className="mobile header-body">
      <h1 className="title"> Shisei Stock Info </h1>
    </div>
    <div className="desktop header-body">
      <div className="title-box">
        <ul className="title-box_text">
          <li className="box-bottom text">Shisei Book Info</li>
        </ul>
      </div>
    </div>
  </header>
)

export const CheerHeader = () => (
  <header id="header" className="header cheer" >
    <div className="header-body clearfix">
      <div className="title-box_text">
        <h2 className="title">cheer SALE</h2>
      </div>
    </div>
  </header>
)

export const NewCheerHeader = () => (
  <header id="header" className="header cheer newcheer" >
    <div className="header-body clearfix">
      <div className="title-box_text">
        <h2 className="title">cheer BOOK<span className="accent text">ss</span></h2>
     </div>
    </div>
  </header>
)


export const NewCheerHeader2 = () => (
  <header id="header" className="header cheer newcheer newcheer2" >
    <div className="header-body clearfix">
      <div className="title-box_text">
        <h2 className="title">cheer <span className="accent-primary">SALE</span></h2>
        <div className="contact-box">
          <h3 className="contact-name">SHISEI HANBAI Co.,Ltd.</h3>
        </div>
      </div>
    </div>
  </header>
)


export const CheerFashionHeader = () => (
  <header id="header" className="header cheer newcheer cheerclothes" >
    <div className="header-body clearfix">
      <div className="title-box_text">
        <h2 className="title">cheer <span className="accent-primary">CLOTHES</span></h2>
        <div className="contact-box">
          <h3 className="contact-name">SHISEI HANBAI Co.,Ltd.</h3>
        </div>
      </div>
    </div>
  </header>
)


export const PHeader = () => (
  <header id="header" className="header pstock" >
    <div className="header-body clearfix">
      <div className="title-box_text">
        <h2 className="title">P Stocks</h2>
        <div className="contact-box">
          <h3 className="contact-name">SHISEI HANBAI Co.,Ltd.</h3>
        </div>
      </div>
    </div>
  </header>
)


export const PentagonHeader = () => (
  <header id="header" className="header pstock" >
    <div className="header-body clearfix">
      <div className="title-box_text">
        <h2 className="title">Pentagon</h2>
        <div className="contact-box">
          <h3 className="contact-name">SHISEI HANBAI Co.,Ltd.</h3>
        </div>
      </div>
    </div>
  </header>
)


export const CustomHeader = ({ type, title,titleColor, period, periodColor, year, yearColor, bgColor, accentColor, optionalTitle }) => {


  if( type==='typeA'){
    let breakLinePos = period.indexOf("\\n");

    let periodText  = <span className="text-period" style={{ color: periodColor }}>{period}</span>;
    if( breakLinePos > 0){
      periodText  = (
        <div>
          <div className="text-period" style={{ color: periodColor }}>{period.substring(0,breakLinePos)}</div>
          <div className="text-period" style={{ color: periodColor }}>{period.substring(breakLinePos+2)}</div>
        </div>
      );
    }

    return <header id="header" className="header custom-typea" style={{ backgroundColor: bgColor }}>
      <div className="mobile header-body">
        <h1 className="text-title"><span style={{color: periodColor}}>{year}</span> </h1>
      </div>
      <div className="desktop header-body" >
        <div className="title-boxes row">
          <div className="title-box box-year">
            <span className="number-year" style={{ color: yearColor }}>{year}</span>
          </div>
          <div className="title-box box-period">
            { periodText }
          </div>
          <div className="title-box">
            <span className="text-title" style={{ color: titleColor }}>{title}</span>
          </div>

        </div>
      </div>
    </header>

  }else if(type==='typeB'){
    return <header id="header" className="header cheer newcheer newcheer2" style={{backgroundColor: bgColor}}>
      <div className="header-body clearfix">
        <div className="title-box_text">
          <h2 className="title" style={{color:titleColor}}>{title} <span className="accent-primary" style={{color: accentColor}}>{ optionalTitle }</span> <span className="accent" style={{color:yearColor}}>{year}</span><span className="accent text" style={{periodColor}}>{period}</span></h2>
       </div>
      </div>
    </header>

  }else if(type==='typeC'){
    return <header id="header" className="header sales christmas" >
    <div className="mobile header-body">
      <h1 className="title" style={{color: titleColor}}>{title}</h1>
    </div>
    <div className="desktop header-body">
      <div className="title-box">
        <ul className="title-box_text">
          <li className="box-upper text" style={{color:periodColor}}>{period}</li>
          <li className="box-bottom text" style={{color:titleColor}}>{title}</li>
        </ul>
      </div>
    </div>
  </header>
  }else if(type==='typeD'){
    return <header id="header" className="header sales" style={{ backgroundColor: bgColor }} >
      <div className="desktop header-body">
        <div className="title-box">
          <ul className="title-box_text">
            <li className="box-upper text" style={{ color:periodColor }}>{period}</li>
            <li className="box-bottom text" style={{ color: titleColor }}>{title}</li>
          </ul>
        </div>
      </div>
    </header>
  }


}
