import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { queryCheck } from "../../utility/utility";
import "./home.css";

const env = process.env.NODE_ENV;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogName: "",
    };
  }

  render() {
    document.title = `志成販売 カタログリスト`;
    return (
      <div>
        <header className="home-header">
          <h1 className="home-header-title">
            <span>志成販売</span>
            <span>カタログリスト</span>
          </h1>
        </header>
        <div className="container list">
          <div className="row">
            <NavLink
              target="_blank"
              className="list-catalogue christmas col-xs-4"
              to="/christmas/catalogue?password=023109238172819"
            >
              <p>christmas</p>
            </NavLink>
            <NavLink
              target="_blank"
              className="list-catalogue zakka col-xs-4"
              to="/zakka/catalogue?password=023109238172819"
            >
              <p>20 SS</p>
              <p>雑貨 Sales</p>
            </NavLink>
            <NavLink
              target="_blank"
              className="list-catalogue stockbook col-xs-4"
              to="/stockbook/catalogue?password=023109238172819"
            >
              <p>Stock Book</p>
            </NavLink>
            <NavLink
              target="_blank"
              className="list-catalogue stockbook col-xs-4"
              to="/books/catalogue?password=023109238172819"
            >
              <p>Book</p>
            </NavLink>
            {queryCheck("pcatalogue", "yes") || env === "development" ? (
              <NavLink
                target="_blank"
                className="list-catalogue pstock col-xs-4"
                to="/p/catalogue?password=023109238172819"
              >
                <p>P Stocks</p>
              </NavLink>
            ) : (
              ""
            )}
            {queryCheck("pentagon", "yes") || env === "development" ? (
              <NavLink
                target="_blank"
                className="list-catalogue pstock col-xs-4"
                to="/pentagon/catalogue?password=023109238172819"
              >
                <p>Pentagon</p>
              </NavLink>
            ) : (
              ""
            )}
            {queryCheck("netsuite", "yes") || env === "development" ? (
              <NavLink
                target="_blank"
                className="list-catalogue pstock col-xs-4"
                to="/netsuite/catalogue?password=023109238172819"
              >
                <p>全ての商品</p>
              </NavLink>
            ) : (
              ""
            )}
            {queryCheck("netsuite", "yes") || env === "development" ? (
              <div className="list-catalogue pstock col-xs-4">
                <input
                  value={this.state.catalogName}
                  placeholder="...から始まるコード"
                  onChange={(event) =>
                    this.setState({ catalogName: event.target.value })
                  }
                />
                <NavLink
                  target="_blank"
                  to={
                    "/current_catalog/catalogue?password=023109238172819&custitem_ns_komida=" +
                    this.state.catalogName
                  }
                >
                  <p>現行カタログ</p>
                </NavLink>
              </div>
            ) : (
              ""
            )}
            <NavLink
              target="_blank"
              className="list-catalogue pstock col-xs-4"
              to="/cardType3/catalogue?password=023109238172819"
            >
              <p>cardType3</p>
            </NavLink>
            <NavLink
              target="_blank"
              className="list-catalogue pstock col-xs-4"
              to="/cardType2/catalogue?password=023109238172819"
            >
              <p>cardType2</p>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
