// for PRD
const REDIRECT_URI = "https://shiseihanbai.info";
const CLIENT_ID = "1f01eb5c1427cbfe25b8f5558871d2123d324196cc07d732e74befc298792106";

// for DEV
// const REDIRECT_URI = "https://localhost:3000";
// const CLIENT_ID = "b4d120c8ad0d279401828d3055fef4ae2752a1472784e82436d9ce50b5e90593";


const PRODUCT_API_KEY = "shiseihanbaiisgoingtochangetheworldforsure";
const ZAKKA_SALES_PRODUCT_API = "https://script.google.com/macros/s/AKfycbwznBy9Q9-96Ww2mBtP0kdkwAGT_7mzbogeUfQ7NLIbkNpLZaEehA5_0cOuxQWgmPrbzQ/exec";
const P_SALES_PRODUCT_API = "https://script.google.com/macros/s/AKfycbyzw0ad-a0yldBaD2VwaMYUGbaeWkMjBK78Nc_I/exec";
const PENTAGON_SALES_PRODUCT_API = "https://script.google.com/macros/s/AKfycbyBsLaKFrtmUk3Y69LGkQdfP-kWDix1FsMjRokZgQ/exec";
const CHRISTMAS_PRODUCT_API = "https://script.google.com/macros/s/AKfycbxN4EfqeGk9fzNbrtHRlua0tWWdfTU8rr3Vb57yJw/exec";
const STOCKBOOK_PRODUCT_API = "https://script.google.com/macros/s/AKfycbzTeHW813fl13M80aArXJ0-E7tCEkVd351P5xvxj6xDlXbpgfcPZ8Ivt04QD1_IzTma/exec";
const BOOKS_PRODUCT_API = "https://script.google.com/macros/s/AKfycbyIVcw36-1ntiY4c5iTFkVpg7h-K658Jcag3q4/exec";
const NS_PRODUCT_API = "https://a8wvg75fci.execute-api.ap-northeast-1.amazonaws.com/default/getProductListDataFromNS?url="+REDIRECT_URI; 

/*
 *  PRODUCT_API_URL&query=***&color=***&category&priceRange=***,***
 */

export const PRODUCT_API_URL = {
  zakka_sales: `${ZAKKA_SALES_PRODUCT_API}?key=${PRODUCT_API_KEY}&active=Yes`,
  books_sales: `${BOOKS_PRODUCT_API}?key=${PRODUCT_API_KEY}&active=Yes`,
	christmas_sales: `${CHRISTMAS_PRODUCT_API}?key=${PRODUCT_API_KEY}&active=Yes`,
	stockbook_sales: `${STOCKBOOK_PRODUCT_API}?key=${PRODUCT_API_KEY}&active=Yes`,
  p_sales: `${P_SALES_PRODUCT_API}?key=${PRODUCT_API_KEY}&active=Yes`,
  pentagon_sales: `${PENTAGON_SALES_PRODUCT_API}?key=${PRODUCT_API_KEY}`,
  netsuite_sales: NS_PRODUCT_API,
  current_catalog_sales: NS_PRODUCT_API ,
  cardType2_sales:  `${STOCKBOOK_PRODUCT_API}?key=${PRODUCT_API_KEY}&active=Yes`,
  cardType3_sales:  `${STOCKBOOK_PRODUCT_API}?key=${PRODUCT_API_KEY}&active=Yes`
}


export const CATALOGUE_PASS = "023109238172819";
export const NS_LOGIN_API = "https://5408941.app.netsuite.com/app/login/oauth2/authorize.nl?redirect_uri="+REDIRECT_URI+"&client_id="+CLIENT_ID+"&response_type=code&scope=restlets&state=ykv2XLx1BpT5Q0F3MRPHasds";
